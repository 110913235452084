import MarketEvalCard from "../components/MarketEvalCard";
import PastTenYearsTable from "../components/PastTenYearsTable";

const marketEvalData = {
  PE_Ratio: 28.15,
  PFCF_Ratio: 23.05,
  PB_Ratio: 15.8,
  PS_Ratio: 5.4,
  EV_OPI_Ratio: 22.3,
  EV_EBIT_Ratio: 20.1,
  EV_EBITDA_Ratio: 18.6,
  EV_S_Ratio: 4.9,
};

const MarketEvaluation = (props: any) => {
  const { ticker } = props;

  return (
    <>
      <h2>市場評價</h2>
      <div className="market-card-container">
        {Object.entries(marketEvalData).map((item: Object, idx: number) => {
          return <MarketEvalCard key={idx} info={item} />;
        })}
      </div>
      <h2>過去10年表格</h2>
      <div className="table-container">
        <PastTenYearsTable />
      </div>

      <p className="disclaimer">
        上表之數字均以當前最新股價、配合歷史財務數據計算得到。舉例而言，2023年之P/E(本益比)使用「最新之收盤價」÷「2023年全年每股盈餘」而得。
      </p>
    </>
  );
};

export default MarketEvaluation;

import { Divider } from "antd";
import "chart.js/auto";
import { Line } from "react-chartjs-2";
import StockDetailsCard from "../components/StockDetailsCard";

const infoOne = {
  收盤: "1,065",
  前日收盤: "1,060",
  漲跌: "+5",
  漲跌幅: "0.47%",
};

const infoTwo = {
  開盤: "1,050",
  最高: "1,075",
  最低: "1,050",
  振幅: "25",
};

const infoThree = {
  成交金額: "467億",
  "成交量(張)": "43,860",
  "3個月平均成交量(張)": "40,561",
  最新股數: "259億",
};

const infoFour = {
  "P/E": "29.96",
  "P/B": "7.23",
  "P/FCF": "38.38",
  現金股利殖利率: "1.41%",
};

const infoFive = {
  市值: "27.62兆",
  "企業價值(EV)": "",
  過去4季EPS: "35.61元",
  過去4季每股FCF: "27.77元",
};

const Overview = (props: any) => {
  const { ticker } = props;
  const cfg = {
    labels: ["jan", "feb", "march", "apr", "may", "june", "july"],
    datasets: [
      {
        label: "",
        data: [865, 659, 680, 781, 756, 955, 1040],
        backgroundColor: "rgba(88, 194, 169, 0.25)",
        fill: true,
        borderColor: "#58c2a9",
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <>
      <div className="stock-watchlist-container">
        <div className="stock-card">
          <div className="stock-info-wrapper">
            <h2>股價資訊</h2>
            <div className="stock-info">
              <div className="stock-chart">
                <Line data={cfg} options={options} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="stock-details-container">
        <StockDetailsCard set={infoOne} />
        <StockDetailsCard set={infoTwo} />
        <StockDetailsCard set={infoThree} />
        <StockDetailsCard set={infoFour} />
        <StockDetailsCard set={infoFive} />
      </div>
    </>
  );
};

export default Overview;

import "./style.scss";

const FinanceCard = (props: any) => {
  const { key, info, keySet, unit } = props;
  const title: string = info[0];
  const data: string = info[1];

  return (
    <div key={key} className="finance-card">
      <h2>{keySet[title as keyof typeof keySet]}</h2>
      <span>2024 年第 2 季</span>
      <h3 className="data">
        {data} {unit}
      </h3>
    </div>
  );
};

export default FinanceCard;

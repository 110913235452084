import "./style.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import MarketEvaluation from "../../pages/MarketEvaluation";
import FinanceEvaluation from "../../pages/FinanceEvaluation";
import Overview from "../../layouts/Overview";

const types: string[] = ["總覽", "價值投資", "財務分析"];
const financeSubtitles: string[] = ["重要指標", "每月營收"];

const IndivStock = () => {
  let { tickerId } = useParams();
  const [active, setActive] = useState<string>(types[0]);
  const [finacesubtitle, setFinacensubtitle] = useState<string>(
    financeSubtitles[0]
  );

  useEffect(() => {
    // call api to get data here
  }, []);

  return (
    <div className="wrapper">
      <div className="container">
        <header className="header-title">
          <h1>台積電 (2330) 股票分析</h1>
          <p>台積電 (TSMC) 股票代號 2330 | 臺灣上市</p>
          <ul className="data-items">
            {types.map((type, idx) => (
              <li
                onClick={() => setActive(types[idx])}
                className={active === type ? "selected" : ""}
              >
                {type}
              </li>
            ))}
          </ul>
          <div className="subtitle-container">
            {active == types[2] && (
              <>
                {financeSubtitles.map((item) => (
                  <span
                    className={finacesubtitle == item ? "selected" : ""}
                    onClick={() => setFinacensubtitle(item)}
                  >
                    {item}
                  </span>
                ))}
              </>
            )}
          </div>
        </header>

        {active == types[0] && <Overview ticker={tickerId} />}
        {active == types[1] && <MarketEvaluation ticker={tickerId} />}
        {active == types[2] && (
          <FinanceEvaluation ticker={tickerId} subtitle={finacesubtitle} />
        )}
      </div>
    </div>
  );
};

export default IndivStock;

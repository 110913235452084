import "./style.scss";
import { marketEvalDic } from "../../utility/common/common";

const MarketEvalCard = (props: any) => {
  const { key, info } = props;
  const title: string = info[0];
  const data: string = info[1];

  return (
    <div key={key} className="market-card">
      <h2>{marketEvalDic[title as keyof typeof marketEvalDic]}</h2>
      <span>過去 4 季</span>
      <h3 className="data">{data}</h3>
    </div>
  );
};

export default MarketEvalCard;

import "./style.scss";
import { Table } from "antd";
import type { TableColumnsType } from "antd";
interface DataType {
  key: React.Key;
  titles: string;
  2024: string;
  2023: string;
  2022: string;
  2021: string;
  2020: string;
  2019: string;
  2018: string;
  2017: string;
  2016: string;
  2015: string;
  2014: string;
}

const dataSource: DataType[] = [
  {
    key: "1",
    titles: "9月營收",
    2024: "251,872,717",
    2023: "180,430,282",
    2022: "208,247,663",
    2021: "152,685,418",
    2020: "127,584,492",
    2019: "102,170,096",
    2018: "94,921,920",
    2017: "88,578,988",
    2016: "89,702,807",
    2015: "64,514,083",
    2014: "74,846,313",
  },
  {
    key: "2",
    titles: "MoM",
    2024: "0.4%",
    2023: "-4.38%",
    2022: "-4.53%",
    2021: "11.1%",
    2020: "3.83%",
    2019: "-3.72%",
    2018: "4.25%",
    2017: "-3.63%",
    2016: "-4.89%",
    2015: "-3.76%",
    2014: "8.04%",
  },
  {
    key: "3",
    titles: "YoY",
    2024: "39.6%",
    2023: "-13.36%",
    2022: "36.39%",
    2021: "19.67%",
    2020: "24.87%",
    2019: "7.64%",
    2018: "7.16%",
    2017: "-1.25%",
    2016: "39.04%",
    2015: "-13.8%",
    2014: "35.14%",
  },
  {
    key: "4",
    titles: "3年年化成長率",
    2024: "18.16%",
    2023: "12.25%",
    2022: "26.79%",
    2021: "17.17%",
    2020: "12.93%",
    2019: "4.43%",
    2018: "13.74%",
    2017: "5.78%",
    2016: "17.44%",
    2015: "",
    2014: "",
  },
  {
    key: "5",
    titles: "5年年化成長率",
    2024: "19.78%",
    2023: "13.71%",
    2022: "18.65%",
    2021: "11.22%",
    2020: "14.61%",
    2019: " 6.42%",
    2018: "11.38%",
    2017: "",
    2016: "",
    2015: "",
    2014: "",
  },
  {
    key: "6",
    titles: "10年年化成長率",
    2024: "12.9%",
    2023: "12.54%",
    2022: "",
    2021: "",
    2020: "",
    2019: "",
    2018: "",
    2017: "",
    2016: "",
    2015: "",
    2014: "",
  },
];

const columns: TableColumnsType<DataType> = [
  {
    title: "(TWD千元)",
    dataIndex: "titles",
    fixed: "left",
    key: "titles",
    render: (text) => <strong>{text}</strong>,
  },
  { title: "2024", dataIndex: "2024", key: "2024" },
  { title: "2023", dataIndex: "2023", key: "2023" },
  { title: "2022", dataIndex: "2022", key: "2022" },
  { title: "2021", dataIndex: "2021", key: "2021" },
  { title: "2020", dataIndex: "2020", key: "2020" },
  { title: "2019", dataIndex: "2019", key: "2019" },
  { title: "2018", dataIndex: "2018", key: "2018" },
  { title: "2017", dataIndex: "2017", key: "2017" },
  { title: "2016", dataIndex: "2016", key: "2016" },
  { title: "2015", dataIndex: "2015", key: "2015" },
  { title: "2014", dataIndex: "2014", key: "2014" },
];

const MonthlyRevGrowthRateTable = (props: any) => {
  return (
    <div className="monthly-rev-growth-rate-table">
      <h2 className="highlight-info">
        台積電(2330) 2024 年 9 月單月營收為 NT$2,519 億元，為過去 10
        年同期中的第 1 高
      </h2>
      <div className="comparison-chart">
        <div className="prev-year">
          <h3>與去年同期相比</h3>
          <p>
            台積電(2330) 今年 9 月營收較去年同期 (YoY) 成長 39.6%，達到 NT$2,519
            億元。同時台積電過去 3 年、5 年與 10 年的「 9 月份營收成長率」分別為
            18.16%、19.78% 與 12.9%。
          </p>
        </div>
        <div className="prev-month">
          <h3>與前月相比</h3>
          <p>
            而今年9月相較前月的月成長率 (MoM)
            為0.4%。過去10年台積電9月營收成長率 (MoM)
            中位數為-1.61%、最高值為11.1%、最低值為-4.89%。
          </p>
        </div>
      </div>
      <h2>單月營收成長率</h2>
      <Table
        dataSource={dataSource}
        columns={columns}
        bordered={false}
        pagination={false}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default MonthlyRevGrowthRateTable;

import IFRSRevTable from "../components/IFRSRevTable";
import MonthlyRevGrowthRateTable from "../components/MonthlyRevGrowthRateTable";

const Revenue = (props: any) => {
  const { ticker } = props;
  return (
    <>
      <h2>採用 IFRSs 後之月營業收入資訊</h2>
      <div className="table-container">
        <IFRSRevTable />
      </div>
      <h2>單月營收成長率</h2>
      <div className="table-container">
        <MonthlyRevGrowthRateTable />
      </div>
    </>
  );
};

export default Revenue;

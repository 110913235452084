import moment from "moment";
import { Item } from "../interface/common";

export const categories: Item[] = [
  {
    id: "geopolitics",
    title: "geopolitics",
  },
  {
    id: "technology",
    title: "technology",
  },
  {
    id: "economics",
    title: "economics",
  },
  {
    id: "biomedicine",
    title: "biomedicine",
  },
  {
    id: "industry",
    title: "industry",
  },
];

export const langs: Item[] = [
  {
    id: "en_",
    title: "English",
  },
  { id: "zh_tw_", title: "繁體中文" },
  { id: "zh_cn_", title: "简体中文" },
  { id: "ja_", title: "日本語" },
  { id: "es_", title: "Español" },
  { id: "ko_", title: "한국어" },
];

export const processDate = (publishedTime: string) => {
  if (!window.navigator.userAgent.includes("Chrome")) {
    let date = publishedTime.split("UTC");
    return date[0];
  }
  return moment(publishedTime).format("MMMM DD, YYYY HH:MM");
};

export const marketEvalDic = {
  PE_Ratio: "P/E(本益比)",
  PFCF_Ratio: "P/FCF(股價自由現金流比)",
  PB_Ratio: "P/B(股價淨值比)",
  PS_Ratio: "P/S(股價營收比)",
  EV_OPI_Ratio: "EV/OPI(企業價值÷營業利益)",
  EV_EBIT_Ratio: "EV/EBIT(企業價值÷EBIT)",
  EV_EBITDA_Ratio: "EV/EBITDA(企業價值÷EBITDA)",
  EV_S_Ratio: "EV/S(企業價值÷營收)",
};

export const financeDataTitle = {
  operating_revenue: "營業收入",
  gross_profit: "營業毛利",
  operating_income: "營業利益",
  net_income: "淨利",
  cash_flow_from_operating_activities: "營業活動之現金流",
  net_cash_flow_from_investing_activities: "投資活動之淨現金流",
  net_cash_flow_from_financing_activities: "籌資活動之淨現金流",
  free_cash_flow: "自由現金流",
};

export const perShareDataTitle = {
  per_share_operating_revenue: "每股營收",
  per_share_gross_profit: "每股營業毛利",
  per_share_operating_income: "每股營業利益",
  per_share_eps: "每股盈餘(EPS)",
  per_share_cash_flow_from_operating_activities: "每股營業現金流",
  per_share_free_cash_flow: "每股自由現金流",
  per_share_neg_debt: "每股有息負債",
  per_share_net_income: "每股淨值",
};

export const ratioDataTitle = {
  return_assets_ratio: "資產報酬率",
  return_equity_ratio: "股東權益報酬率",
  gross_profit_total_assets: "營業毛利÷總資產",
  roce_ratio: "ROCE",
  operating_gross_profit_margin: "營業毛利率",
  operating_profit_rate: "營業利益率",
  net_interest_rate: "淨利率",
  operating_cash_flow_profit_margin: "營業現金流利潤率",
};

import "./style.scss";
import { useEffect } from "react";
import "chart.js/auto";
import { useNavigate, useParams } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";

import { data } from "../../../comparison.js";
import TileCard from "../TileCard";

const StockOverview = () => {
  let { tickerId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // call api to get data here
    console.log(tickerId);
  }, []);

  return (
    <div className="wrapper">
      <div className="container">
        <h1>熱門股票</h1>
        <section className="popular-stocks">
          <div
            className="popular-stock-item"
            onClick={() => navigate("/tw-stock/2330")}
          >
            <div className="title-price">
              <h3>台積電</h3>
              <div className="details">
                <span>2330</span>
                <span className="percentage">+1.55%</span>
              </div>
            </div>
            <div className="price-chart">
              <span>
                <span>目前價值</span>
                <h2 className="price">15,215.70</h2>
              </span>
              <img src={`images/chart-green.png`} />
            </div>
          </div>
          <div
            className="popular-stock-item"
            onClick={() => navigate("/tw-stock/3231")}
          >
            <div className="title-price">
              <h3>緯創</h3>
              <div className="details">
                <span>3231</span>
                <span className="percentage">+0.25%</span>
              </div>
            </div>
            <div className="price-chart">
              <span>
                <span>目前價值</span>
                <h2 className="price">20,120.35</h2>
              </span>
              <img src={`images/chart-green.png`} />
            </div>
          </div>
          <div
            className="popular-stock-item"
            onClick={() => navigate("/tw-stock/3037")}
          >
            <div className="title-price">
              <h3>欣興</h3>
              <div className="details">
                <span>3037</span>
                <span className="percentage dec">-0.55%</span>
              </div>
            </div>
            <div className="price-chart">
              <span>
                <span>目前價值</span>
                <h2 className="price">12,280.40</h2>
              </span>
              <img src={`images/chart-red.png`} />
            </div>
          </div>
          <div
            className="popular-stock-item"
            onClick={() => navigate("/tw-stock/1504")}
          >
            <div className="title-price">
              <h3>東元</h3>
              <div className="details">
                <span>1504</span>
                <span className="percentage">+3.28%</span>
              </div>
            </div>
            <div className="price-chart">
              <span>
                <span>目前價值</span>
                <h2 className="price">28,590.13</h2>
              </span>
              <img src={`images/chart-green.png`} />
            </div>
          </div>
          <div
            className="popular-stock-item"
            onClick={() => navigate("/tw-stock/8069")}
          >
            <div className="title-price">
              <h3>元太</h3>
              <div className="details">
                <span>8069</span>
                <span className="percentage dec">-1.37%</span>
              </div>
            </div>
            <div className="price-chart">
              <span>
                <span>目前價值</span>
                <h2 className="price">32,140.20</h2>
              </span>
              <img src={`images/chart-red.png`} />
            </div>
          </div>
          <span
            className="next-btn"
            onClick={() => {
              navigate("/popular-stocks");
            }}
          >
            <RightOutlined />
          </span>
        </section>

        <h1>全台銀行各種利率查詢與比較</h1>
        <div className="tile-container">
          {data.map((item: any) => {
            return <TileCard url={""} key={item.id} title={item.title} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default StockOverview;

import FinanceCard from "../components/FinanceCard";

// keySets
import { financeDataTitle } from "../utility/common/common";
import { perShareDataTitle } from "../utility/common/common";
import { ratioDataTitle } from "../utility/common/common";

const financeData = {
  operating_revenue: "73,510,177",
  gross_profit: "358,124,478",
  operating_income: "286,555,542",
  net_income: "247,661,438",
  cash_flow_from_operating_activities: "377,668,210",
  net_cash_flow_from_investing_activities: "-197,607,330",
  net_cash_flow_from_financing_activities: "-90,244,583",
  free_cash_flow: "171,993,526",
};

const perShareData = {
  per_share_operating_revenue: "25.98",
  per_share_gross_profit: "13.81",
  per_share_operating_income: "11.05",
  per_share_eps: "9.56",
  per_share_cash_flow_from_operating_activities: "14.57",
  per_share_free_cash_flow: "6.63",
  per_share_neg_debt: "39.56",
  per_share_net_income: "147.35",
};

const ratioData = {
  return_assets_ratio: "16.98%",
  return_equity_ratio: "26.47%",
  gross_profit_total_assets: "6.09%",
  roce_ratio: "25.49%",
  operating_gross_profit_margin: "53.17%",
  operating_profit_rate: "42.55%",
  net_interest_rate: "36.77%",
  operating_cash_flow_profit_margin: "56.07%",
};

const Vitals = (props: any) => {
  const { ticker } = props;
  return (
    <>
      <h2>財務概況</h2>
      <div className="finance-card-container">
        {Object.entries(financeData).map((item: Object, idx: number) => {
          return (
            <FinanceCard
              key={idx}
              info={item}
              keySet={financeDataTitle}
              unit={"千元"}
            />
          );
        })}
      </div>
      <h2>每股財務狀況</h2>
      <div className="finance-card-container">
        {Object.entries(perShareData).map((item: Object, idx: number) => {
          return (
            <FinanceCard
              key={idx}
              info={item}
              keySet={perShareDataTitle}
              unit={"元"}
            />
          );
        })}
      </div>

      <h2>獲利能力</h2>
      <div className="finance-card-container">
        {Object.entries(ratioData).map((item: Object, idx: number) => {
          return <FinanceCard key={idx} info={item} keySet={ratioDataTitle} />;
        })}
      </div>
    </>
  );
};

export default Vitals;

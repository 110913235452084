import Revenue from "../layouts/Revenue";
import Vitals from "../layouts/Vitals";

const FinanceEvaluation = (props: any) => {
  const { ticker, subtitle } = props;

  return (
    <>
      {subtitle == "重要指標" && <Vitals ticker={ticker} />}
      {subtitle == "每月營收" && <Revenue ticker={ticker} />}
    </>
  );
};

export default FinanceEvaluation;

import "./style.scss";
import { Table } from "antd";
import type { TableColumnsType } from "antd";
interface DataType {
  key: React.Key;
  titles: string;
  113: string;
  112: string;
  111: string;
  110: string;
  109: string;
  108: string;
  107: string;
  106: string;
  105: string;
  104: string;
  103: string;
}

const dataSource: DataType[] = [
  {
    key: "1",
    titles: "1~9月",
    113: "2,025,846,521",
    112: "1,536,206,985",
    111: "1,638,359,424",
    110: "1,149,225,731",
    109: "977,721,754",
    108: "752,748,383",
    107: "741,703,364",
    106: "699,876,957",
    105: "685,711,092",
    104: "639,978,805",
    103: "--",
  },
  {
    key: "2",
    titles: "12月",
    113: "--",
    112: "176,299,866",
    111: "192,560,242",
    110: "155,382,230",
    109: "117,364,912",
    108: "103,313,138",
    107: "89,830,598",
    106: "89,897,252",
    105: "78,112,022",
    104: "58,347,005",
    103: "--",
  },
  {
    key: "3",
    titles: "11月",
    113: "--",
    112: "206,026,179",
    111: "222,705,993",
    110: "148,267,599",
    109: "124,865,438",
    108: "107,884,396",
    107: "98,389,414",
    106: "93,153,310",
    105: "93,029,912",
    104: "63,428,163",
    103: "--",
  },
  {
    key: "4",
    titles: "10月",
    113: "--",
    112: "243,202,811",
    111: "210,265,633",
    110: "134,539,477",
    109: "119,302,707",
    108: "106,039,531",
    107: "101,550,181",
    106: "94,519,722",
    105: "91,085,318",
    104: "81,743,395",
    103: "--",
  },
  {
    key: "5",
    titles: "9月",
    113: "251,872,717",
    112: "180,430,282",
    111: "208,247,663",
    110: "152,685,418",
    109: "127,584,492",
    108: "102,170,096",
    107: "94,921,920",
    106: "88,578,988",
    105: "89,702,807",
    104: "64,514,083",
    103: "--",
  },
  {
    key: "6",
    titles: "8月",
    113: "250,866,368",
    112: "188,686,256",
    111: "218,132,391",
    110: "137,427,162",
    109: "122,878,244",
    108: "106,117,619",
    107: "91,055,038",
    106: "91,917,167",
    105: "94,311,424",
    104: "67,038,016",
    103: "--",
  },
  {
    key: "7",
    titles: "7月",
    113: "256,953,058",
    112: "177,616,220",
    111: "186,762,689",
    110: "124,557,799",
    109: "105,963,468",
    108: "84,757,724",
    107: "74,370,924",
    106: "71,611,190",
    105: "76,391,654",
    104: "80,952,810",
    103: "--",
  },
  {
    key: "8",
    titles: "6月",
    113: "207,868,693",
    112: "156,404,174",
    111: "175,874,001",
    110: "148,470,660",
    109: "120,877,785",
    108: "85,867,929",
    107: "70,438,298",
    106: "84,187,305",
    105: "81,390,752",
    104: "59,955,479",
    103: "--",
  },
  {
    key: "9",
    titles: "5月",
    113: "229,620,372",
    112: "176,537,345",
    111: "185,705,425",
    110: "112,359,668",
    109: "93,819,010",
    108: "80,436,931",
    107: "80,968,732",
    106: "72,795,974",
    105: "73,575,784",
    104: "70,154,763",
    103: "--",
  },
  {
    key: "10",
    titles: "4月",
    113: "236,021,112",
    112: "147,899,735",
    111: "172,561,382",
    110: "111,314,794",
    109: "96,001,572",
    108: "74,693,615",
    107: "81,869,781",
    106: "56,871,933",
    105: "66,843,310",
    104: "75,329,510",
    103: "--",
  },
  {
    key: "11",
    titles: "3月",
    113: "195,210,804",
    112: "145,408,332",
    111: "171,966,525",
    110: "129,127,388",
    109: "113,519,599",
    108: "79,721,587",
    107: "103,697,437",
    106: "85,875,181",
    105: "73,088,805",
    104: "72,269,001",
    103: "--",
  },
  {
    key: "12",
    titles: "2月",
    113: "181,648,270",
    112: "163,174,097",
    111: "146,933,238",
    110: "106,533,690",
    109: "93,394,449",
    108: "60,889,055",
    107: "64,640,562",
    106: "71,422,961",
    105: "59,551,321",
    104: "62,645,075",
    103: "--",
  },
  {
    key: "13",
    titles: "1月",
    113: "215,785,127",
    112: "200,050,544",
    111: "172,176,110",
    110: "126,749,152",
    109: "103,683,135",
    108: "78,093,827",
    107: "79,740,672",
    106: "76,616,258",
    105: "70,855,235",
    104: "87,120,068",
    103: "--",
  },
];

const columns: TableColumnsType<DataType> = [
  {
    title: "(TWD千元)",
    dataIndex: "titles",
    fixed: "left",
    key: "titles",
    render: (text) => <strong>{text}</strong>,
  },
  { title: "113", dataIndex: "113", key: "113" },
  { title: "112", dataIndex: "112", key: "112" },
  { title: "111", dataIndex: "111", key: "111" },
  { title: "110", dataIndex: "110", key: "110" },
  { title: "109", dataIndex: "109", key: "109" },
  { title: "108", dataIndex: "108", key: "108" },
  { title: "107", dataIndex: "107", key: "107" },
  { title: "106", dataIndex: "106", key: "106" },
  { title: "105", dataIndex: "105", key: "105" },
  { title: "104", dataIndex: "104", key: "104" },
  { title: "103", dataIndex: "103", key: "103" },
];

const IFRSRevTable = (props: any) => {
  return (
    <div className="ifrs-rev-table">
      <Table
        dataSource={dataSource}
        columns={columns}
        bordered={false}
        pagination={false}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default IFRSRevTable;

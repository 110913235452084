import "./style.scss";
import { Table } from "antd";
import type { TableColumnsType } from "antd";
interface DataType {
  key: React.Key;
  titles: string;
  pastFourSeasons: string;
  2023: string;
  2022: string;
  2021: string;
  2020: string;
  2019: string;
  2018: string;
  2017: string;
  2016: string;
  2015: string;
  2014: string;
}

const dataSource: DataType[] = [
  {
    key: "1",
    titles: "P/E(本益比)",
    pastFourSeasons: "29.81",
    2023: "32.81",
    2022: "27.03",
    2021: "46.04",
    2020: "53.05",
    2019: "79.6",
    2018: "78.27",
    2017: "80.11",
    2016: "82.22",
    2015: "89.67",
    2014: "大於100",
  },
  {
    key: "2",
    titles: "P/FCF(股價自由現金流比)",
    pastFourSeasons: "38.2",
    2023: "94.09",
    2022: "52.07",
    2021: "大於100",
    2020: "87.15",
    2019: "大於100",
    2018: "大於100",
    2017: "大於100",
    2016: "大於100",
    2015: "大於100",
    2014: "大於100",
  },
  {
    key: "3",
    titles: "P/B(股價淨值比)",
    pastFourSeasons: "7.2",
    2023: " 7.89",
    2022: " 9.29",
    2021: "12.66",
    2020: "14.85",
    2019: "16.95",
    2018: "16.39",
    2017: "18.05",
    2016: "19.78",
    2015: "22.48",
    2014: "26.29",
  },
  {
    key: "4",
    titles: "P/S(股價營收比)",
    pastFourSeasons: "11.27",
    2023: "12.72",
    2022: "12.14",
    2021: "17.32",
    2020: "20.53",
    2019: "25.69",
    2018: "26.65",
    2017: "28.12",
    2016: "29",
    2015: "32.59",
    2014: "36.04",
  },
  {
    key: "5",
    titles: "EV/OPI(企業價值÷營業利益)",
    pastFourSeasons: "不適用",
    2023: "不適用",
    2022: "不適用",
    2021: "不適用",
    2020: "不適用",
    2019: "不適用",
    2018: "不適用",
    2017: "不適用",
    2016: "不適用",
    2015: "不適用",
    2014: "不適用",
  },
  {
    key: "6",
    titles: "EV/EBIT(企業價值÷EBIT)",
    pastFourSeasons: "不適用",
    2023: "不適用",
    2022: "不適用",
    2021: "不適用",
    2020: "不適用",
    2019: "不適用",
    2018: "不適用",
    2017: "不適用",
    2016: "不適用",
    2015: "不適用",
    2014: "不適用",
  },
  {
    key: "7",
    titles: "EV/EBITDA(企業價值÷EBITDA)",
    pastFourSeasons: "不適用",
    2023: "不適用",
    2022: "不適用",
    2021: "不適用",
    2020: "不適用",
    2019: "不適用",
    2018: "不適用",
    2017: "不適用",
    2016: "不適用",
    2015: "不適用",
    2014: "不適用",
  },
  {
    key: "8",
    titles: "EV/S(企業價值÷營收)",
    pastFourSeasons: "不適用",
    2023: "不適用",
    2022: "不適用",
    2021: "不適用",
    2020: "不適用",
    2019: "不適用",
    2018: "不適用",
    2017: "不適用",
    2016: "不適用",
    2015: "不適用",
    2014: "不適用",
  },
];

const columns: TableColumnsType<DataType> = [
  {
    title: "各式乘數",
    dataIndex: "titles",
    fixed: "left",
    key: "titles",
    render: (text) => <strong>{text}</strong>,
  },
  { title: "過去4季", dataIndex: "pastFourSeasons", key: "pastFourSeasons" },
  { title: "2023年", dataIndex: "2023", key: "2023" },
  { title: "2022年", dataIndex: "2022", key: "2022" },
  { title: "2021年", dataIndex: "2021", key: "2021" },
  { title: "2020年", dataIndex: "2020", key: "2020" },
  { title: "2019年", dataIndex: "2019", key: "2019" },
  { title: "2018年", dataIndex: "2018", key: "2018" },
  { title: "2017年", dataIndex: "2017", key: "2017" },
  { title: "2016年", dataIndex: "2016", key: "2016" },
  { title: "2015年", dataIndex: "2015", key: "2015" },
  { title: "2014年", dataIndex: "2014", key: "2014" },
];

const PastTenYearsTable = (props: any) => {
  return (
    <div className="past-ten-years-table">
      <Table
        dataSource={dataSource}
        columns={columns}
        bordered={false}
        pagination={false}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default PastTenYearsTable;
